export default {
  meta: {
    title: 'Hurricane season is here | FloodHelpNY.org',
    url: 'https://www.floodhelpny.org/en/prepare-for-hurricane-season',
    description:
      'Take four easy steps today to protect your home and finances from flooding'
  },
  header: 'Hurricane season is here',
  subHeader: 'Take four easy steps today to protect your home from flooding',
  photos:
    '<b>Take photos</b> of your important belongings to help expedite insurance claims',
  copies:
    '<b>Put copies</b> of important documents in a waterproof container or <b>save</b> electronically',
  move:
    '<b>Move</b> valuable items from the basement to the upper floors to prevent water damage',
  insurance:
    '<b>Get</b> flood insurance or <b>review</b> your current policy to ensure that it is up-to-date',
  posterCallToAction: 'Get the poster',
  posterCopy: 'Download and print your own copy of the checklist here.',
  posterButton: 'Download',
  posterUrl: '/samples/FloodHelpNY-Prepare-Poster-2020.pdf',
  camera: 'Camera',
  waves: 'Waves'
};
